import { usePageContext } from 'vike-react/usePageContext'
import { localeDefault } from 'src/locales/locales'
import { Link } from '@chakra-ui/react'

const LocaleLink = ({ href, ...props }) => {
  const pageContext = usePageContext()
  const locale = pageContext.locale
  if (locale !== localeDefault) {
    href = '/' + locale + href
  }
  return <Link href={href} {...props} />
}

export default LocaleLink;